<template>
  <router-view/>
<!--  <img alt="Vue logo" src="./assets/logo.png">-->
<!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'



export default {
  name: 'App',
  // mounted() {
  //   this.$router.push('/qrLogin')
  // }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  //margin-top: 60px;

  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  background-color: #eee;
  //max-width: 50vw;
  margin: 0;
  overflow: hidden;
}
</style>
